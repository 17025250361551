"use strict";
/* NAV BAR */
const primaryNav = document.querySelector(".primary-nav");
const menuToggle = document.querySelector(".menu-toggle");

menuToggle.addEventListener("click", () => {
  const visibility = primaryNav.getAttribute("data-visible");
  visibility === "false"
    ? primaryNav.setAttribute("data-visible", "true")
    : primaryNav.setAttribute("data-visible", "false");
});

/* GALLERY */
const instaArray = Array.from(document.getElementsByClassName("insta"));
const instaLinkArray = Array.from(
  document.getElementsByClassName("insta-link")
);
let feedArray = [];

const renderFeed = function (array) {
  array.forEach((post, i) => {
    post.parentNode.href = feedArray[i].permalink;
    post.src = feedArray[i].url;
    post.alt = feedArray[i].caption;
  });
};

fetch("https://feeds.behold.so/6747ZkRQJp6zSHypQHQT")
  .then((data) => data.json())
  .then((photos) => {
    photos.forEach(
      (
        {
          mediaType,
          id, // The post ID
          mediaUrl, // The image source '
          caption, // Post caption
          permalink, // URL of the Instagram post
        },
        i
      ) => {
        if (photos[i].mediaType === "VIDEO") return;
        feedArray.push({
          mediaType: mediaType,
          id: id,
          url: mediaUrl,
          caption: caption,
          permalink: permalink,
        });
      }
    );
    renderFeed(instaArray);
  });

/* SLIDER FUNCTIONALITY */
const slider = document.querySelector("testimonials-slider");
const slides = document.querySelectorAll(".slide");
const btnLeft = document.querySelector(".slider__btn--left");
const btnRight = document.querySelector(".slider__btn--right");
const dotContainer = document.querySelector(".dots");

slides.forEach((s, i) => (s.style.transform = `translateX(${150 * i}%`));

let curSlide = 0;
const maxSlide = slides.length;

const createDots = function () {
  slides.forEach((_, i) => {
    dotContainer.insertAdjacentHTML(
      "beforeend",
      `<button class="dots__dot" data-slide="${i}"></button>`
    );
  });
};
createDots();
const dots = document.querySelectorAll(".dots__dot");

const moveSlides = function () {
  slides.forEach(
    (s, i) => (s.style.transform = `translateX(${100 * (i - curSlide)}vw`)
  );
  dots.forEach((dot) => dot.classList.remove("dots__dot--active"));
  dots[curSlide].classList.add("dots__dot--active");
};
moveSlides();

const moveRight = function () {
  curSlide === maxSlide - 1 ? (curSlide = 0) : curSlide++;
  moveSlides();
};
const moveLeft = function () {
  curSlide === 0 ? (curSlide = maxSlide - 1) : curSlide--;
  moveSlides();
};

btnRight.addEventListener("click", moveRight);
btnLeft.addEventListener("click", moveLeft);
document.onkeydown = function (e) {
  const key = e.key;
  if (!(key === "ArrowLeft" || key === "ArrowRight")) return;
  key === "ArrowLeft" && moveLeft();
  key === "ArrowRight" && moveRight();
};
dotContainer.addEventListener("click", function (e) {
  if (e.target.classList.contains("dots__dot")) {
    const { slide } = e.target.dataset;
    curSlide = Number(slide);
    moveSlides();
  }
});

let touchstartX = 0;
let touchendX = 0;

function checkDirection() {
  if (touchendX < touchstartX) moveRight();
  if (touchendX > touchstartX) moveLeft();
}

document.addEventListener("touchstart", (e) => {
  touchstartX = e.changedTouches[0].screenX;
});

document.addEventListener("touchend", (e) => {
  touchendX = e.changedTouches[0].screenX;
  checkDirection();
});
